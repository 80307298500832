const GwasFiles = [
  {
    name: 'ANA_C2_V2.2.ALL.EUR.109.191.PLINK2.20200626.txt.gz (51 MB)',
    url: `/datasets/ANA_C2_V2.2.ALL.EUR.109.191.PLINK2.20200626.txt.gz`,
  },
];

const Covid19SeverityFiles = [
  {
    name: 'ADMIXTURE_ASSOCIATION_SUMMARY_STATS_ALL_SITES.zip (Updated in March, 2022 with patient phenotype metadata - 1.1 MB)',
    url: `/datasets/ADMIXTURE_ASSOCIATION_SUMMARY_STATS_ALL_SITES.zip`,
  },
  {
    name: 'ALLELE_LEVEL_HLA_TYPING_vs_COVID-19_SEVERITY.zip (13.5 KB)',
    url: `/datasets/ALLELE_LEVEL_HLA_TYPING_vs_COVID-19_SEVERITY.zip`,
  },
  {
    name: 'SEROTYPE_LEVEL_HLA_TYPING_vs_COVID-19_SEVERITY.zip (12.6 KB)',
    url: `/datasets/SEROTYPE_LEVEL_HLA_TYPING_vs_COVID-19_SEVERITY.zip`,
  },
  {
    name: 'HUMAN_TRANSCRIPTOMICS_by_COVID-19_SEVERITY.zip (168 MB)',
    url: `/datasets/HUMAN_TRANSCRIPTOMICS_by_COVID-19_SEVERITY.zip`,
  },
  {
    name: 'METAGENOMICS_by_COVID-19_SEVERITY.zip (Updated in March, 2022 with patient phenotype metadata - 2.3 MB)',
    url: `/datasets/METAGENOMICS_by_COVID-19_SEVERITY.zip`,
  },
  {
    name: 'SUMMARY_ALL_PATIENTS_PHENOTYPE.zip (1.3 KB)',
    url: `/datasets/SUMMARY_ALL_PATIENTS_PHENOTYPE.zip`,
  },
];

export { GwasFiles, Covid19SeverityFiles };
